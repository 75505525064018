<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <div class="" style="margin-top: 30rpx">
      <div class="banner-wrap">
        <div class=" " style="color: #f0f2f2; font-size: 16px"><router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link to="/diseaseConsultation/diseasePage">辨病论治 </router-link><i class="el-icon-arrow-right"></i><a style="color: #666666">辨病论治系统</a></div>
      </div>
    </div>
    <div class="startBox">
      <div class="startBoxleft">
        <div class="startBoxleftTop" ref="scrollDiv">
          <!-- 题库答题 -->

          <!-- 结果 -->
          <div class="" style="padding-left: 10px; padding-right: 15px">
            <div class="leftbox" style="align-items: baseline">
              <!-- <div class="" style="width: 45px;height: 45px;">
                                <img src="@/assets/img/zdrugConsult/zdrugren.png" mode="" style="width: 100%;height: 100%;">
                            </div> -->
              <div class="resultMessage">
                <!-- 结果显示 -->
                <div class="" style="padding: 15px">
                  <div class="resultimg">
                    <!-- <img src="@/assets/img/zdrugConsult/resultback.png" alt="" srcset="" style="width: 100%;height: 100%;"> -->
                  </div>
                  <!-- 为了图片111111111 -->
                  <div style="">
                    <div class="endbox">
                      <div class="flexlr" style="align-items: center">
                        <div style="font-size: 16px; font-weight: bold; display: flex; align-items: center">
                          <div class="ring"></div>
                          <div style="margin-left: 5px">{{ resultList.userName }}</div>
                          <div class="" style="margin-left: 10px">
                            {{ resultList.gender ?  "女":  "男"}}
                          </div>
                          <div class="" style="margin-left: 10px">
                            {{ resultList.ageName }}
                          </div>
                          <div class="" style="margin-left: 10px">
                            {{ resultList.userPhone ? resultList.userPhone.substr(0, 3) + "****" + resultList.userPhone.substr(7) : "" }}
                          </div>
                        </div>
                        <div class="" style="text-align: right; font-size: 12px; color: #999999">
                          {{ resultList.diagnosisTime }}
                        </div>
                      </div>
                      <div class="" style="font-size: 15px; color: #666666; padding-top: 5px;padding-left:25px">
                        您的基础病：
                        <span>{{ resultList.answerDiseaseName?resultList.answerDiseaseName:'暂未填写' }}</span>
                      </div>
                      <div class="flexlr" style="font-size: 28px; padding-left: 23px; padding-top: 14px">
                        <span >{{ resultList.primarySymptomName }}</span>
                        <span style="font-size: 14px;cursor: pointer;"  @click="goprimaryZdetail(resultList.diseaseHashKey)">疾病详情<i class="el-icon-arrow-right"></i></span>
                      </div>
                      <div class="flexlf" style="padding-left: 23px; padding-top: 14px">
                        <p style="margin-left:10px" v-if="resultList.categoryFullName">科室:{{ resultList.categoryFullName }}</p>
                        <p style="margin-left:10px" v-if="resultList.primarySymptomAlias">别名:{{ resultList.primarySymptomAlias }}</p>
                      </div>
                      <div class="" style="font-size: 15px; color: #666666; padding: 14px 0; padding-left: 23px">
                        <p style="color: #df7d1f">您的症状清单：</p>
                        <span style="color: #df7d1f" v-for="(itemsym, indexsym) in resultList.symptoms" :key="indexsym"> {{ "【" + itemsym + "】" }}</span>
                      </div>
                    </div>
                    <div class="endbox1" v-for="(item, index) in resultList.recordResults" :key="index">
                      <div v-if="item.matchingRate > 60 || item.matchingRate == 60" @click="godetail(item)">
                        <div class="boxtag1">{{ item.matchingRate }}%匹配度</div>
                        <div class="flexlr" style="align-items: center; margin-top: 15px">
                          <div class="" style="font-size: 16px; font-weight: bold; display: flex; align-items: center">
                            <div class="ring1"></div>
                            <div class="" style="margin-left: 10px">
                              {{ item.syndromeName }}
                            </div>
                          </div>
                        </div>
                        <div class="flexlr" style="padding: 14px 0; align-items: center">
                          <div class="" style="font-size: 15px; color: #666666; width: 85%">
                            <div v-if="item.tongueSymptoms || item.pulseSymptoms || item.symptoms">
                              <p>主要症状：</p>
                              <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                                {{ "【" + itemq.name + "】" }}
                              </span>
                            </div>
                          </div>
                          <div style="width: 24px; height: 24px">
                            <img src="@/assets/img/zdrugConsult/right1.png" alt="" srcset="" style="width: 100%; height: 100%" />
                          </div>
                        </div>
                      </div>
                      <div v-if="item.matchingRate < 60 && item.matchingRate >= 30" @click="godetail(item)">
                        <div class="boxtag2">{{ item.matchingRate }}%匹配度</div>
                        <div class="flexlr" style="align-items: center; margin-top: 15px">
                          <div class="" style="font-size: 16px; font-weight: bold; display: flex; align-items: center">
                            <div class="ring2"></div>
                            <div class="" style="margin-left: 10px">
                              {{ item.syndromeName }}
                            </div>
                          </div>
                        </div>
                        <div class="flexlr" style="padding: 14px 0; align-items: center">
                          <div class="" style="font-size: 15px; color: #666666; width: 85%">
                            <div v-if="item.tongueSymptoms || item.pulseSymptoms || item.symptoms">
                              <p>主要症状：</p>
                              <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                                {{ "【" + itemq.name + "】" }}
                              </span>
                            </div>
                          </div>
                          <div style="width: 24px; height: 24px">
                            <img src="@/assets/img/zdrugConsult/right2.png" alt="" srcset="" style="width: 100%; height: 100%" />
                          </div>
                        </div>
                      </div>
                      <div v-if="item.matchingRate < 30" @click="godetail(item)">
                        <div class="boxtag3">{{ item.matchingRate }}%匹配度</div>
                        <div class="flexlr" style="align-items: center; margin-top: 15px">
                          <div class="" style="font-size: 16px; font-weight: bold; display: flex; align-items: center">
                            <div class="ring3"></div>
                            <div class="" style="margin-left: 10px">
                              {{ item.syndromeName }}
                            </div>
                          </div>
                        </div>
                        <div class="flexlr" style="padding: 14px 0; align-items: center">
                          <div style="font-size: 15px; color: #666666; width: 85%">
                            <div v-if="item.tongueSymptoms || item.pulseSymptoms || item.symptoms">
                              <p>主要症状：</p>
                              <span :class="itemq.checked ? 'newChecked' : ''" v-for="(itemq, indexq) in item.newsyndromeSymptoms" :key="indexq">
                                {{ "【" + itemq.name + "】" }}
                              </span>
                            </div>
                          </div>
                          <div style="width: 24px; height: 24px">
                            <img src="@/assets/img/zdrugConsult/right3.png" alt="" srcset="" style="width: 100%; height: 100%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="startBoxleftBot">
          <div class="startBoxleftBotn">
            <el-button round type="danger" @click="chooseAnswer">删除记录</el-button>
            <el-button round type="primary" @click="toPage">再次测试</el-button>
          </div>
        </div>
      </div>
      <div class="startBoxright">
        <div class="" v-for="(queitem, indexque) in infoList" :key="indexque">
          <div style="margin-top: 20px; padding-left: 10px; padding-right: 15px">
            <div class="leftbox">
              <div class="" style="width: 45px; height: 45px">
                <img src="@/assets/img/zdrugConsult/zdrugren.png" mode="" style="width: 100%; height: 100%" />
              </div>
              <div class="leftMessage">
                {{ queitem.questionName }}
                <div v-for="(item, index) in queitem.dataInfo" :key="index">
                  <div class="" v-if="item.questionFileUrl" style="display: flex; padding-top: 5px; flex-wrap: wrap">
                    <div class="" v-for="(itemimg, indeximg) in item.questionFileUrl" :key="indeximg">
                      <img :src="http + itemimg" style="width: 60px; height: 60px; border-radius: 4px; margin-left: 3px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightbox">
              <div class="rightMessage">
                <div class="" v-for="(item, index) in queitem.dataInfo" :key="index">
                  {{ item.questionOptionName }}
                  <div v-if="item.questionOptionFileUrl" style="display: flex; padding-top: 5px; flex-wrap: wrap">
                    <div class="" v-for="(itemimgins, indeximgins) in item.questionOptionFileUrl" :key="indeximgins">
                      <img :src="http + itemimgins" style="width: 60px; height: 60px; border-radius: 4px; margin-left: 3px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-dialog modal title="提示" :visible.sync="dialogVisibleDetail" width="60%">
          <div class="dialogTitle">
            {{ selfDiagnosisPrimarySymptomDetail.primarySymptomName }}
          </div>
          <div class="textdetail" v-if="selfDiagnosisPrimarySymptomDetail.symptomDescription">
            <div class="">
              <p v-html="selfDiagnosisPrimarySymptomDetail.description.replace(/\n/g, '</p> <p>')"></p>
            </div>
          </div>
          <!-- <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisibleDetail = false">取 消</el-button>
                        <el-button type="warning" @click="gostartSure">确 定</el-button>
                    </span> -->
        </el-dialog>

        <!-- <div class="rightBottomBox">
                    <div class="flexlr" style="padding: 0 30px">
                        <div style="text-align: center;">
                            <div style="width:102px;height:102px ;">
                                <img src="../../assets/img/ampCode.jpg" alt="" srcset="" style="width:100%;height:100%">
                            </div>
                              <span>微信小程序</span>
                        </div>
                        <div style="text-align: center;">
                            <div style="width:102px;height:102px;">
                                <img src="../../assets/img/code_d3.png" alt="" srcset="" style="width:100%;height:100%">
                            </div>
                              <span>APP下载(安卓)</span>
                        </div>
                    </div>
                    <div class="rightBottom">
                        扫码收藏小程序或下载千越医助手APP 随时随地开启中医问诊，您身边的健康助手
                    </div>
                </div> -->
      </div>
    </div>
    <ft-com></ft-com>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import { zdrugconsultationMain } from "../../components/zdrugconsultation/zdrugconsultation";
export default {
  components: {
    ftCom,
    nhCom,
  },
  data() {
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    return {
      zdrugconsultationMain: zdrugconsultation,
      resultList: {},
      http: "",
      infoList: [],
      dialogVisibleDetail: false,
      selfDiagnosisPrimarySymptomDetail: {},
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.getUserSelfDiagnosisRecordResultList(id);
    this.http = this.Services.Statics;
  },
  methods: {
    // 主症详情
    getSelfDiagnosisPrimarySymptomDetailList(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptomDetail(
        e,
        function (data) {
          _this.selfDiagnosisPrimarySymptomDetail = data.data;
          _this.dialogVisibleDetail = true;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    godetail(e) {
      this.$router.push({
        path: "/diseaseConsultation/diseasesynDetail",
        query: {
          id: e.syndromeId,
          hashKey:this.resultList.diseaseHashKey
        },
      });
    },
    goprimaryZdetail(e){
      if (e) {
           this.$router.push({
        name: "diseaseDetail",
        query: {
          hashKey: e,
          searchType: 'disease'
        },
      });
      } else {
        this.$message.error('暂未关联疾病');
      }
   
    },
    // 症状分类
    getUserSelfDiagnosisRecordResultList(e) {
      var _this = this;
      _this.zdrugconsultationMain.getUserSelfDiagnosisRecordResult(
        e,
        function (data) {
          if (data.data.age == 0) {
            data.data.ageName = "6岁以下";
          } else if (data.data.age == 7) {
            data.data.ageName = "7-12岁";
          } else if (data.data.age == 13) {
            data.data.ageName = "13-17岁";
          } else if (data.data.age == 18) {
            data.data.ageName = "18-45岁";
          } else if (data.data.age == 46) {
            data.data.ageName = "46-69岁";
          } else if (data.data.age == -1) {
            data.data.ageName = data.data.primarySymptomMinAge + "-" + data.data.primarySymptomMaxAge + "岁";
          } else {
            data.data.ageName = "69岁以上";
          }
          _this.resultList = data.data;
          _this.resultList.diagnosisTime = _this.resultList.diagnosisTime.slice(0, 10);
          let contantList = [];
          for (let i = 0; i < _this.resultList.recordResults.length; i++) {
            if (_this.resultList.recordResults[i].pulseSymptoms == null) {
              _this.resultList.recordResults[i].pulseSymptoms = [];
            } else if (_this.resultList.recordResults[i].symptoms == null) {
              _this.resultList.recordResults[i].symptoms = [];
            } else if (_this.resultList.recordResults[i].tongueSymptoms == null) {
              _this.resultList.recordResults[i].tongueSymptoms = [];
            } else {
              console.log("123");
            }
            contantList = _this.resultList.recordResults[i].pulseSymptoms.concat(_this.resultList.recordResults[i].symptoms.concat(_this.resultList.recordResults[i].tongueSymptoms));
            _this.resultList.recordResults[i].contantLists = contantList;
            let newsyndromeSymptoms1 = [];
            for (let k = 0; k < _this.resultList.recordResults[i].syndromeSymptoms.length; k++) {
              newsyndromeSymptoms1.push({
                name: _this.resultList.recordResults[i].syndromeSymptoms[k],
                checked: false,
              });
            }
            _this.resultList.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
          }
          for (let m = 0; m < _this.resultList.recordResults.length; m++) {
            for (let n = 0; n < _this.resultList.recordResults[m].contantLists.length; n++) {
              for (let j = 0; j < _this.resultList.recordResults[m].newsyndromeSymptoms.length; j++) {
                if (_this.resultList.recordResults[m].newsyndromeSymptoms[j].name == _this.resultList.recordResults[m].contantLists[n]) {
                  _this.resultList.recordResults[m].newsyndromeSymptoms[j].checked = true;
                }
              }
            }
          }
          _this.zdrugconsultationMain.getUserSelfDiagnosisRecordInfo(
            e,
            function (data) {
              for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].questionFileUrl) {
                  data.data[i].questionFileUrl = JSON.parse(data.data[i].questionFileUrl);
                }
                if (data.data[i].questionOptionFileUrl) {
                  data.data[i].questionOptionFileUrl = JSON.parse(data.data[i].questionOptionFileUrl);
                }
              }
              _this.infoList = _this.checkSameData(data.data);
              for (let j = 0; j < _this.infoList.length; j++) {
                if (_this.infoList[j].dataInfo.length > 1) {
                  _this.infoList[j].dataInfo = _this.infoList[j].dataInfo.reverse();
                }
              }
            },
            function (error) {
              console.log(error);
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
    },
    // 滚动到底部
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.scrollDiv;
        scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: "smooth" });
      });
    },
    toPage() {
      this.$router.push("/diseaseConsultation/diseaseStart");
    },
    chooseAnswer() {
      var _this = this;
      _this
        .$confirm("是否删除此条记录?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
        .then(() => {
          _this.zdrugconsultationMain.getUserSelfDiagnosisRecordDelete(
            _this.$route.query.id,
            function (data) {
              _this.$message({
                type: "success",
                message: "删除成功!",
              });
              _this.$router.push("/personal/SelfTest");
            },
            function (error) {
              console.log(error);
              _this.$message({
                type: "warning",
                message: "删除失败!",
              });
            }
          );
        })
        .catch(() => {});
    },
    checkSameData(resData) {
      let tempArr = [];
      let Data = [];
      for (let i = 0; i < resData.length; i++) {
        if (tempArr.indexOf(resData[i].questionName) == -1) {
          Data.push({
            questionName: resData[i].questionName,
            dataInfo: [resData[i]],
            questionOptionName: resData[i].questionOptionName,
          });
          tempArr.push(resData[i].questionName);
        } else {
          for (let j = 0; j < Data.length; j++) {
            if (Data[j].questionName == resData[i].questionName) {
              Data[j].dataInfo.push(resData[i]);
              break;
            }
          }
        }
      }
      return Data;
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";

::v-deep .el-dialog {
  width: 28% !important;
  margin-top: 20vh !important;
}

.dialogTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.leftbox {
  display: flex;
  align-items: center;
}

.leftMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 0px 15px 15px 15px;
  margin-left: 10px;
}

.resultMessage {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  margin-left: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.16);
  border-radius: 16px;
}

.rightbox {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.rightMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #ee9e51;
  border-radius: 15px 0px 15px 15px;
  color: #fff;
}

.startBox {
  width: 1200px;
  margin: 30px auto;
  padding: 40px;
  box-shadow: 0px 6px 10px 0px rgba(204, 204, 204, 0.18);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/img/zdrugConsult/zdrugStartChart.jpg");
}

.startBoxleft {
  position: relative;
  width: 60%;
  height: 720px;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}

.startBoxleftTop {
  height: 680px;
  overflow: hidden;
  overflow-y: auto;
}

/*滚动条样式*/
.startBoxleftTop::-webkit-scrollbar {
  width: 4px;
}

.startBoxleftTop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  background: #ccc;
}

.startBoxleftTop::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  border-radius: 10px;
  background: #ffffff;
}

.startBoxleftBot {
  /* overflow-x:scroll; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
  border-top: 1px solid #e6e6e6;
  position: relative;
}

.startBoxleftBotn {
  margin-top: 12px;
  text-align: right;
  margin-right: 30px;
}

.startBoxright {
  margin-left: 2%;
  width: 38%;
  height: 720px;
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}

/*滚动条样式*/
.startBoxright::-webkit-scrollbar {
  width: 4px;
}

.startBoxright::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  background: #ccc;
}

.startBoxright::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  border-radius: 10px;
  background: #ffffff;
}

.surebtn {
  background: linear-gradient(180deg, #ffb163, #ee9e51);
  font-size: 16px;
  color: #ffffff;
  padding: 17rpx 0;
  width: 100%;
  text-align: center;
}

.goodTagCheck {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #ffe6cd;
  border-radius: 20px;
  margin-left: 10px;
  color: #df7d1f;
}

.goodTag {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 10px;
}

/* 症状 */
.textdetail {
  font-size: 14px;
  color: #666666;
  padding: 15px;
  background-color: #f2f2f2;
  display: flex;
  border-radius: 12px;
  margin-top: 15px;
}

.viptable {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
}

.viptableY {
  width: 100%;
  height: 150px;
  overflow: hidden;
  overflow-y: scroll;
}

.endbox {
  margin-top: -10px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
}

.endboxright {
  margin-top: 15px;
  padding: 10px 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
}

.ring {
  width: 10px;
  height: 10px;
  border: 3px solid #cf7f31;
  box-shadow: 0px 2px 3px 0px rgba(202, 127, 55, 0.3);
  border-radius: 50%;
}

.endbox1 {
  margin-top: 20px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(214, 214, 214, 0.3);
  border-radius: 10px;
  position: relative;
  border: 1px solid #ffe5cb;
}

.ring1 {
  width: 6px;
  height: 6px;
  background: #df7d1f;
  border-radius: 50%;
}

.ring2 {
  width: 6px;
  height: 6px;
  background: #3bc8d9;
  border-radius: 50%;
}

.ring3 {
  width: 6px;
  height: 6px;
  background: #68caa9;
  border-radius: 50%;
}

.boxtag2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background-color: #3bc8d9;
  font-size: 24rpx;
}

.boxtag3 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background: #68caa9;
  font-size: 12px;
}

.boxtag1 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background-color: #f6a11f;
  font-size: 12px;
}

.flexlr {
  display: flex;
  justify-content: space-between;
}
.flexlf {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.resultimg {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-image: url("../../assets/img/zdrugConsult/rstban.png");
  background-color: #f6a11f;
}

.rightBottom {
  padding: 10px 25px;
  background: #f0f2f2;
  border-radius: 25px;
  font-size: 13px;
  color: #999999;
  text-align: center;
}

.rightBottomBox {
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  background: #fff;
}

.radioBox {
  margin: 10px 0;
  font-size: 18px;
}

.imgTag {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 8px;
}

.imgTagCheck {
  background-color: #feaf62;
  /* opacity: 0.5; */
  padding: 5px;
}

.textCheck {
  color: #df7d1f;
}

.hide-tooltip {
  max-width: 400px;
  /* visibility: hidden; */
}

.newChecked {
  color: #df7d1f;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
